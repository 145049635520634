import React from "react"
import styled from "styled-components";
import { Text } from "../../core/commonExports";

import FireIcon from "../../../assets/images/fire_icon_sm.svg";
import BurglarytheftIcon from "../../../assets/images/burglary_theft_icon.svg";
import LightningIcon from "../../../assets/images/lightning_icon.svg";
import RiotsIcon from "../../../assets/images/riots_icon.svg";
import FloodIcon from "../../../assets/images/flood_icon.svg";
import MoreThreats from "../../../assets/images/more_threats_icon.svg";

const perils = [
    {
        icon: <FireIcon className="peril-icon" />,
        title: "Fire",
    },
    {
        icon: <BurglarytheftIcon className="peril-icon" />,
        title: "Burglary and Theft",
    },
    {
        icon: <LightningIcon className="peril-icon" />,
        title: "Lightning",
    },
    {
        icon: <RiotsIcon className="peril-icon" />,
        title: "Riots and Strike",
    },
    {
        icon: <FloodIcon className="peril-icon" />,
        title: "Flood",
    },
    {
        icon: <MoreThreats className="peril-icon" />,
        title: "and 10+ more threats",
    }
]

const PerilsSection = () => {
    return (
        <PerilsContainer>
            {perils.map((peril, index) => (
                <div style={{display: 'flex', alignItems: 'center'}} key={`peril-${index}`}>
                {peril.icon}
                <Text fontSize="16px" mfontSize="12px" lineHeight="20px" mlineHeight="15px" color="#4C4C4C" fontWeight="bold" style={{margin: "8px 0 8px 8px"}}>{peril.title}</Text>
                </div>
            ))}
        </PerilsContainer>
    )
}

const PerilsContainer = styled.div`
    display: grid; 
    grid-template-columns: 1fr 1fr; 
    max-width: 460px; 
    gap: 1rem 1.5rem;
    margin-top: 3rem;

    .peril-icon {
        width: 34px;
        height: 34px;
    }  

    @media screen and (max-width: 768px) {
        margin: 8px 0;
        gap: 0 0.5rem;
        .peril-icon {
            width: 24px;
            height: 24px;
        }
    }
`

export default PerilsSection